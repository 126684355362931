import * as React from 'react';
import { login } from '../utils/auth';

const Login = (): React.ReactElement => {
  login();

  return <p>Redirecting you to login page...</p>;
};

export default Login;
